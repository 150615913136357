<template>
    <div class="action-workday">
        <template>
            <div class="message">
                <div class="message-content">
                    <div>
                        <p>궁금한게 있으면 편하게 말씀해주세요 :)</p>
                    </div>
                    <div class="grey-box m-t-8">
                        <p class="c-grey-07 spoqa-f-bold f-12">상담가능시간</p>
                        <p class="f-16 spoqa-f-bold c-black">평일 오전 9시~오후 6시</p>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>
<script>
export default {
    name: 'ActionOnboard',
    props: ['message'],
}
</script>

<style lang="scss" scoped>
.action-onboard {
    padding: 0 16px 16px;
    color: #111111;

    .noti-title {
        @include spoqa-f-bold;
        font-size: 17px;
        color: #111111;
        padding: 0 2px;
        margin-bottom: 8px;
        display: flex;
        align-items: center;
    }
    .noti-body {
        font-size: 14px;
        margin-top: 8px;
        color: #111111;
        line-height: 26px;
    }
    .noti-blue-box {
        margin-top: 8px;
        background: #f4fcff;
        border-radius: 8px;
        color: #111111;
        padding: 4px 8px;
        font-size: 14px;
        line-height: 24px;
        .noti-time {
            font-weight: bold;
            font-size: 16px;
            line-height: 26px;
        }
    }
    .noti-button {
        margin-top: 16px;
        ::v-deep button {
            height: 40px;
        }
    }
}
</style>
